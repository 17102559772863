import React from 'react';

import { header } from 'components/lexemes';
import { CdnImagePath } from 'lib/cdn-image-path';
import { getImageData } from 'lib/get-image-data';
import Image from 'lib/image-container';
import LocaleContext from 'lib/locale-context';
import { MediaQueryWidth } from 'lib/use-media-query';
import useScrollbarWidth from 'lib/use-scrollbar-width';

import Button from 'components/button/button';
import { CtaBoxContent } from 'components/cta-box';

type NavSlideoverCtaProps = {
    feature:
        | 'opening-moves'
        | 'video-chat'
        | 'travel-mode'
        | 'incognito-mode'
        | 'snooze-mode'
        | 'compliments'
        | 'superswipe'
        | 'web-app';
    isMobile?: boolean;
};

const PAGE_PADDING_SMALL = '16px';
const PAGE_PADDING_MEDIUM = '64px';
const PAGE_PADDING_LARGE = '108px';

function HeaderNavigationSlideoverCta({ feature }: NavSlideoverCtaProps) {
    const scrollbarWidth = useScrollbarWidth(true);

    const lang = React.useContext(LocaleContext);
    const travelImagePath = `${CdnImagePath}/bumble/l-assets/${lang}/features/TravelMode-Howdoestravelmodework.jpg`;

    const [isLoaded, setIsLoaded] = React.useState(false);
    const handleImageLoaded = () => setIsLoaded(true);
    const blurDataUrl = getImageData(travelImagePath).blurDataUrl;
    const objectPosition = '50% 33%';
    return (
        <div className="slideover-cta__block">
            {feature === 'travel-mode' && (
                <div className="slideover-cta__block-container">
                    <h3 className="header-3 slideover-cta__header">
                        {header.lxHeaderCtaTitleFeaturesTravelMode}
                    </h3>

                    <CtaBoxContent width="stretch">
                        <div
                            className="page-section__picture"
                            style={
                                blurDataUrl
                                    ? {
                                          backgroundImage: `url("${blurDataUrl}")`,
                                          backgroundSize: 'cover',
                                          backgroundPosition: objectPosition,
                                          borderRadius: '8px',
                                      }
                                    : undefined
                            }
                        >
                            <Image
                                width={1218}
                                height={508}
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: '50% 33%',
                                    opacity: blurDataUrl && !isLoaded ? 0 : 1,
                                    transition: 'opacity 0.5s',
                                }}
                                quality={75}
                                src={travelImagePath}
                                alt=""
                                onLoad={handleImageLoaded}
                                sizes={`
                                (max-width: ${MediaQueryWidth['desktop-sm']}px) calc(100vw - 2 * ${PAGE_PADDING_SMALL} - ${scrollbarWidth}),
                                (max-width: ${MediaQueryWidth['tablet-lg']}px) calc(100vw - 2 * ${PAGE_PADDING_MEDIUM} - ${scrollbarWidth}),
                                (max-width: ${MediaQueryWidth['content-max']}px) calc(100vw - 2 * ${PAGE_PADDING_LARGE} - ${scrollbarWidth}),
                                1218px
                            `}
                            />
                        </div>
                    </CtaBoxContent>

                    <p className="slideover-cta__body-text">
                        {header.lxHeaderItemDescriptionFeaturesTravelMode}
                    </p>
                    <div className="slideover-cta__button-container">
                        <Button
                            href={`/${lang}/features/travel-mode/`}
                            text={header.lxHeaderCtaButtonFindOutMore}
                            onClick={() => ''}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default HeaderNavigationSlideoverCta;
